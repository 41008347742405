<script>
export default {
  components: {
   
  },
  props: {
   
  },
  created(){
   
  },
  data() {
    return {
      firstTime: true,
      visible: false
    };
  },
  computed: {
   
  },
  mounted: function () {
   
  },
  methods: {
    toggleRightSidebar() {
      console.log('toggleRightSidebar');
      this.firstTime = false;
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
  },
  watch: {
   
  }
};
</script>

<style scoped lang="scss">
  #todobtn {
    display: none;
    border-radius: 12px 0 0 12px;
    box-shadow: -1px 0 3px 0 #0003;
   
    z-index: 900;
    
    text-transform: uppercase;
    padding: 0 10px;
    outline: none;
    text-decoration: none;
    position: fixed; 
    top: 92px;
    right:0;
    z-index: 9999;

    background: linear-gradient(to left, #F7931E 30%, #662D91);
    background-size: 400% 400%;
    animation: gradient 7s ease infinite;

    /*width: 136px;*/
   
    // -webkit-transform: rotate(-90deg);
    // -moz-transform: rotate(-90deg);
    // transform: rotate(-90deg);
    // -webkit-transform-origin:100% 100%;
    // -moz-transform-origin:100% 100%;
    // transform-origin:100% 100%;
        
  }

  

  .todobtn-icon.enabled {
    
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both infinite;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  
@keyframes gradient {
    0% {
        background-position: 0% 50%;
        width: 50px;
    }
    50% {
        background-position: 100% 50%;
        width: 90px;
    }
    100% {
        background-position: 0% 50%;

         width: 50px;
    }
}
</style>

<template>
 <a href="javascript:void(0)" id="todobtn" class="px-4 py-2 text-center" @click.stop.prevent="toggleRightSidebar" >
   <i class="todobtn-icon  far fa-bell text-white font-size-20" :class="{'enabled': firstTime}"></i>
 </a>
</template>