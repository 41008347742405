<script>

import { layoutComputed } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";

import { parseErrors } from '@/helpers' 
import { mapState, mapMutations, mapActions } from 'vuex';
//import notifications from '@/components/notifications' 
import http from '@/explorer-client'
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
 
  components: {
    //notifications 
  },
  data() {
    return {
       processing: false,
      errors:[],
      showmodal: false,
      submitted: false,
      pwdData:{

      },
    };
  }, 
  mounted() {
    console.log('mounted', this.fullScreen);
  },
  created(){
    console.log('created', this.fullScreen);
  },
  computed: {
     ...layoutComputed,
    ...mapState('layout', {
      fullScreen: 'fullScreen'
    }),
    userName() {
      return this.$store && this.$store.state.auth && this.$store.state.auth.currentUser ? this.$store.state.auth.currentUser.UserName : null;
    },
    userId() {
      return this.$store && this.$store.state.auth && this.$store.state.auth.currentUser ? this.$store.state.auth.currentUser.id : null;
    }
  },
  validations: {
    pwdData: {
      oldPassword: { required },
      newPassword: { required }
     
    }
  },
  methods: {
    ...mapMutations('layout', { changeFullScreen: 'CHANGE_FULLSCREEN' }),
    ...mapActions('auth', { logOut: 'logOut'}),
    hideModal() {
      this.processing = false;
      this.showmodal = false;
      this.submitted = false;
      this.pwdData = {};
      this.errors = [];
    },
    showModal(){
      this.processing = false;
      this.showmodal = true;
      this.submitted = false;
      this.errors = [];
      this.pwdData = {oldPassword: null, newPassword: null};

    },
    handleSubmit() {
      this.submitted = true;
      this.errors = [];
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.processing = true;
        const dto ={...this.pwdData};
        http.put(`identity/password`, dto).then(()=>{
          this.processing = false;
          this.hideModal(true);
        }).catch((error) => {
          this.processing = false;
          this.errors= parseErrors(error);
        });
      }
    },
     initFullScreen() {
    
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
        this.changeFullScreen(true);
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
        this.changeFullScreen(false);
      }      
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-topbar", "dark");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "light");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            case "colored":
              document.body.setAttribute("data-topbar", "colored");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            default:
              document.body.setAttribute("data-topbar", "dark");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-mode", "fluid");
              document.body.removeAttribute("data-layout-size");
              break;
            default:
              document.body.setAttribute("data-layout-mode", "fluid");
              break;
          }
        }
      },
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <b-modal
      v-model="showmodal"
      :title="$t('forms.changePassword.title')"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="handleSubmit">
         <b-alert
          variant="danger"
          class="mt-3"
          v-if="errors.length > 0"
          show
          dismissible
          >
          <ul>
            <li v-for="e in errors" :key="e">{{e}}</li>
          </ul>
        </b-alert>
        <div class="form-group">
          <label for="oldPassword">{{$t('forms.changePassword.oldPassword')}} [*]</label>
          <input
            id="oldPassword"
            v-model="pwdData.oldPassword"
            type="password"
            class="form-control"
            placeholder=""
            :class="{ 'is-invalid': submitted && $v.pwdData.oldPassword.$error }"
          />
          <div
            v-if="submitted && !$v.pwdData.oldPassword.required"
            class="invalid-feedback"
          >{{$t('forms.changePassword.oldPassword-required')}}</div>
        </div>
        <div class="form-group">
          <label for="displayName">{{$t('forms.changePassword.newPassword')}} [*]</label>
          <input
            id="displayName"
            v-model="pwdData.newPassword"
            type="password"
            class="form-control"
            placeholder=""
            :class="{ 'is-invalid': submitted && $v.pwdData.newPassword.$error }"
          />
          <div
            v-if="submitted && !$v.pwdData.newPassword.required"
            class="invalid-feedback"
          >{{$t('forms.changePassword.newPassword-required')}}</div>
        </div> 
        
        <div>
          <b-button class="ml-1 float-left" @click="hideModal"> {{$t('forms.changePassword.close')}}</b-button>
          <button type="submit" class="btn btn-success float-right" :disabled="processing">
            <i class="fa fa-spin fa-spinner" v-if="processing"></i>
            {{$t('forms.changePassword.title')}}
          </button>
        </div>
      </form>
    </b-modal>
    <div class="navbar-header" 
    >
      <div class="brand-block">
        <img
          src="@/assets/logo.jpg"
          class="img-fluid mt-2"
          alt
        />
        <!-- <h3 class="ml-2 appName">
          <div class="c8-block text-center">
            <div class="c8">
              C8
            </div>
            <div class="studio">
              Studio
            </div>
          </div>
          <div class="slogan">
            <div>Create,</div>
            <div>Trade</div>
            <div>&amp; Own</div>
          </div>
        </h3> -->
      </div>
      
      <div class="d-flex">
        <button
          type="button"
          class="btn btn-sm px-3 font-size-24 d-lg-none header-item"
          data-toggle="collapse"
          @click="toggleMenu"
        >
          <i class="ri-menu-2-line align-middle"></i>
        </button>

       
      </div>

      <div class="d-flex">

        <!-- <notifications/> -->
        
        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            data-toggle="fullscreen"
            @click="initFullScreen"
          >
            <i class="ri-fullscreen-line"></i>
          </button>
        </div>
     
        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          class="d-inline-block user-dropdown"
        >
          <template v-slot:button-content>
            
            <span class="d-none d-xl-inline-block ml-1 ">Signed In as: <b class="ml-1 text-highlighted">{{userName}}</b></span>
           
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block "></i>
            <i class="mdi  mdi-account d-inline-block d-xl-none font-size-22 text-highlighted"></i>
           
          </template>

          <!-- <a class="dropdown-item" href="/protfolio-solution-templates">
            <i class="mdi mdi-file-settings-outline align-middle mr-1"></i>
            Solution Templates
          </a>
          <div class="dropdown-devider"></div> -->

          <!-- item-->
          <a class="dropdown-item" href="#" @click="showModal">
            <i class="ri-user-line align-middle mr-1"></i>
              {{$t("top-bar.changePassword")}}
          </a>
         
          <div class="dropdown-divider"></div>
          <a href="javascript:void(0)" class="dropdown-item text-danger" @click="logOut">
            <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            Sign Out
          </a>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>