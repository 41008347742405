export const menuItems = [
   
    {
        id: 10,
        label: 'Home',
        to:{ name: 'home'},
        routes:[
            'home'
        ]
    },
    {
        id: 20,
        label: 'Portfolio Instruments',
       // role: [ROLES.C8Admin, ROLES.C8Manager],
        to: { name: 'instruments'},
        routes:[
            'instruments',
            'instrument-details'
        ]
    },
    // {
    //     id: 1,
    //     label: 'Users',
    //     isAllow: (auth) => 
    //    // role: [ROLES.AccountManager],
    //     to: { name: 'account-users'},
    //     routes:[
    //         'account-users'
    //     ]
    // },
    {
        id: 40,
        label: 'Accounts',
        isAllow: (auth) => auth?.permissions?.accountPermissions?.allowManage,
       // role: [ROLES.C8Admin, ROLES.C8Manager],
        to: { name: 'accounts'},
        routes:[
            'accounts',
            'accounts-edit'
        ]
    },
    {
        id: 50,
        label: 'Users',
        isAllow: (auth) => auth?.permissions?.userPermissions?.allowManage,
       // role: [ROLES.C8Admin, ROLES.C8Manager],
        to: { name: 'users'},
        routes:[
            'users',
            'users-edit'
        ]
    },
    {
        id: 60,
        label: 'Predefined Profiles',
        isAllow: (auth) => auth?.permissions?.predefinedProfilePermissions?.allowManage,
       // role: [ROLES.C8Admin, ROLES.C8Manager],
        to: { name: 'predefined-risk-profiles'},
        routes:[
            'predefined-risk-profiles',
            'predefined-risk-profiles-edit'
        ]
    },
]