<script>
import { layoutMethods } from "@/state/helpers";
/**
 * Right-sidebar 
 */
export default {
  data() {
    return {
      activityData: [
        {
          icon: "mdi-check-bold mdi",
          title: "Create New Risk Profile",
          status: 'done',
          open: true,
           subtitle: "Some explanation text...."
        },
        {
          icon: "mdi-lock-open-variant mdi",
          title: "Create New Portfolio",
          status: 'pending',
          open: false,
          subtitle: "Some explanation text...."
        },
        {
          icon: "ri-lock-fill",
          title: "Start Trading",
          status: 'upcoming',
          open: false,
          subtitle: "Some explanation text...."
        }
      ],
      config: {
        handler: this.handleRightBarClick,
        middleware: this.middleware,
        events: ["click"],
      },
      layout: this.$store ? this.$store.state.layout.layoutType : {} || {},
      width: this.$store ? this.$store.state.layout.layoutWidth : {} || {},
      topbar: this.$store ? this.$store.state.layout.topbar : {} || {},
      sidebarType: this.$store
        ? this.$store.state.layout.leftSidebarType
        : {} || {},
      loader: this.$store ? this.$store.state.layout.loader : {} || {},
    };
  },
  methods: {
    ...layoutMethods,
    hide() {
      this.$parent.toggleRightSidebar();
    },
    createNewRiskProfile() {
      this.hide();
      this.$router.push('/risk-profile-new');
    },
    // eslint-disable-next-line no-unused-vars
    handleRightBarClick(e, el) {
      this.$parent.hideRightSidebar();
    },
    // eslint-disable-next-line no-unused-vars
    middleware(event, el) {
      if (event.target.classList)
        return !event.target.classList.contains("toggle-right");
    },
    changeLayout(layout) {
      this.changeLayoutType({
        layoutType: layout,
      });
    },
    changeType(type) {
      return this.changeLeftSidebarType({
        leftSidebarType: type,
      });
    },
    changeWidth(width) {
      return this.changeLayoutWidth({
        layoutWidth: width,
      });
    },
    changeTopbartype(value) {
      return this.changeTopbar({
        topbar: value,
      });
    },
    changeloader() {
      return this.changeLoaderValue({
        loader: this.loader,
      });
    },
  },
};
</script>


<style scoped lang="scss">
  .right-bar .item-number{
    font-weight: bold;
    width: 22px;
    height: 22px;
    margin-right: 5px;
    display: inline-block;
    border-radius: 50px;
    text-align: center;
    border: 2px solid lightsalmon;
  }
</style>
<template>
  <div>
    <!-- Right Sidebar -->
    <div v-click-outside="config" class="right-bar">
      <div data-simplebar class="h-100">
        <div class="rightbar-title px-3 py-4">
          <a
            href="javascript:void(0);"
            class="right-bar-toggle float-right"
            @click="hide"
          >
            <i class="mdi mdi-close noti-icon"></i>
          </a>
          <h5 class="m-0">TO-DO LIST</h5>
        </div>
        <div class="alert alert-info">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </div>
        <div class="p-2">
          <ul class="list-unstyled activity-wid ">
            <li class="activity-list" v-for="(data, index) in activityData" :key="index">
              <div class="activity-icon avatar-xs">
                <span class="avatar-title  rounded-circle" :class="{'bg-soft-primary text-primary': data.status === 'pending', 'bg-soft-success text-success': data.status === 'done', 'bg-soft-secondary text-secondary': data.status === 'upcoming'}">
                   <i :class="`${data.icon}`"></i>
                </span>
              </div>
              <div>
                <div>
                  <h5 class="font-size-13 text-uppercase">
                    <b>[{{index}}]</b>
                    {{data.title}}
                  </h5>
                </div>

                <div>
                  <p class="text-muted mb-0">{{data.subtitle}}</p>
                </div>
                <div>
                  <span class="badge badge-success" v-if="data.status === 'done'">
                    done
                  </span>
                  <button class="btn btn-sm btn-light mt-2" v-if="data.status === 'pending'" @click="createNewRiskProfile">
                    Start<i class=" ml-1 mdi-chevron-double-right mdi"></i>
                  </button>
                  <button class="btn btn-sm btn-light mt-2" v-if="data.status === 'upcoming'" :disabled="true">
                    <i class=" mr-1 ri-lock-fill"></i> Locked
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- end slimscroll-menu-->
    </div>
    <!-- /Right-bar -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay"></div>
  </div>
</template>